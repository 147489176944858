import PageLayout from "../../../components/PageLayout"
import { observer } from "mobx-react"
import Hero from "../../../components/Hero"
import NavBar from "../../../components/NavBar"
import AboutUs from "../../../components/AboutUs"
import History from "../../../components/History"
import Events from "../../../components/Events"
import Carousele from "../../../components/Carousel"
import Footer from "../../../components/Footer"
import Maps from "../../../components/Maps"


function HomePage() {
  return (
    <PageLayout background={"white"}>
      <NavBar />
      <Hero />
      <AboutUs />
      <History />
      <Events />
      <Carousele />
      <Maps />
      <Footer />
    </PageLayout>
  )
}

export default observer(HomePage)

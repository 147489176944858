import React, { useEffect } from "react"
import { observer } from "mobx-react"
import { useTheme } from "@mui/material/styles"
import { Box, Button, Typography } from "@mui/material"
import fondoHero1 from "../../assets/images/fondoHero2.webp"
import colors from "../../assets/theme/colors"
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined"
import AOS from "aos"
import "aos/dist/aos.css"

function Hero() {
  const theme = useTheme()

  useEffect(() => {
    AOS.init({
      duration: 2000,
      easing: "slide",
      once: true,
    })
  }, [])

  const styles = {
    heroContainer: {
      backgroundImage: `linear-gradient(to right, rgba(0, 0, 0, 0.6), transparent), url(${fondoHero1})`,
      backgroundSize: "cover",
      backgroundPosition: "center",
      height: "720px",
      [theme.breakpoints.down("md")]: {
        height: "570px",
      },
    },
    heroContainer2: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      textAlign: "center",
      height: "100%",

    },
    textContainer: {
      display: "flex",
      width: "100%",
      [theme.breakpoints.down("md")]: {
        width: "90%",
      },
    },
    title1: {
      fontFamily: "'Bodoni Moda', serif",
      fontSize: "49px",
      color: colors.background.default,
      fontWeight: 500,
      textAlign: "left",
      [theme.breakpoints.down("md")]: {
        fontSize: "34px",
        // paddingTop: 14,
      },
    },
    subTitle: {
      fontFamily: "'Montserrat', sans-serif",
      fontSize: "20px",
      color: colors.white.main,
      fontWeight: 500,
      [theme.breakpoints.down("md")]: {
        fontSize: "2px",
      },
    },
    button: {
      bgcolor: colors.white.main,
      borderRadius: 5,
      mt: 4,
      color: colors.textSideNav.focus,
      "&:hover": {
        bgcolor: "#dee2e6",
      },
    },
    button2: {
      // bgcolor: colors.white.main,
      borderColor: colors.white.main,
      borderRadius: 5,
      mt: 4,
      color: colors.white.main,
      "&:hover": {
        bgcolor: colors.white.main,
        color: colors.textSideNav.focus,

      },
    },
    eslogan: {
      textAlign: "left",
      color: colors.white.main,
      fontStyle: "italic",
      // textDecoration: "underline"
    },
    locationContainer: {
      marginTop: -10,
      marginRight: 10,
      display: "flex",
      flexDirection: "row-reverse",
      cursor: "pointer",
      [theme.breakpoints.down("md")]: {
        fontSize: "34px",
        marginRight: 1,
        marginTop: -7,
      },
    },
    locationText: {
      display: "flex",
      alignItems: "center",
      backgroundColor: "#1c1c1c",
      opacity: "0.65",
      color: "#fff",
      padding: 0.6,
      borderRadius: 2,
      justifyContent: "center",
      [theme.breakpoints.down("md")]: {
        fontSize: "12px",
        marginRight: 1,
      },
    },

  }

  return (
    <>
      <Box sx={styles.heroContainer}>
        <Box sx={styles.heroContainer2}>
          <Box sx={styles.textContainer}>
            <Box sx={{ flex: 1, ml: { xs: 0, md: 5 }, paddingX: { xs: 0, md: 10 } }}>
              {/*<Typography sx={styles.eslogan}>~ Un paraíso frente al lago ~</Typography>*/}
              <Typography variant="h1" sx={styles.title1} data-aos={"fade-up"} data-aos-delay={200}>
                HOTEL ACUARIO<br />
                <span
                  style={styles.subTitle}>Reserva directamente con nosotros y recibe la tarifa más baja disponible.</span>
              </Typography>
              <Box display={"flex"} gap={4} data-aos={"fade-up"} data-aos-delay={300}>
                <Button variant="contained" sx={styles.button}
                        onClick={() => window.open("https://wa.me/+595983587892?text=Hola,%20quisiera%20reservar%20una%20habitacion.")}>
                  Reservar
                </Button>
                <Button variant="outlined" sx={styles.button2}
                        onClick={() => window.open("https://wa.me/+595983587892?text=Hola,%20me%20gustaría%20conocer%20más%20sobre%20los%20servicios.")}>
                  Conocer más
                </Button>
              </Box>
            </Box>
            <Box sx={{ flex: 1, display: { xs: "none", md: "flex" } }} />
          </Box>
        </Box>
        <Box sx={styles.locationContainer}
             onClick={() => window.open("https://maps.app.goo.gl/7LYmQ92htooWh1oh7?g_st=ic", "_blank")}>
          <Typography sx={styles.locationText} data-aos={"fade-up"} data-aos-delay={400}>
            <LocationOnOutlinedIcon sx={{ marginRight: 1 }} />
            San Bernardino, Cordillera, Paraguay
          </Typography>
        </Box>
      </Box>
    </>
  )
}

export default observer(Hero)

import React, { useEffect } from "react"
import { observer } from "mobx-react"
import { useTheme } from "@mui/material/styles"
import { Box, Typography } from "@mui/material"
import colors from "../../assets/theme/colors"
import fondoPileta1 from "../../assets/images/fondoPileta1.webp"
import fondoTrago1 from "../../assets/images/trago1.webp"
import comedorImg from "../../assets/images/menuDeLaCasa.jpg"
import volleyImg from "../../assets/images/volley.webp"
import CardImg from "../CardImg"
import AOS from "aos"
import "aos/dist/aos.css"

function AboutUs() {
  const theme = useTheme()

  useEffect(() => {
    AOS.init({
      duration: 2000,
      easing: "slide",
      once: true,
    })
  }, [])

  const styles = {
    heroContainer: {
      backgroundColor: colors.backgroundsColors.secondary,
      height: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      textAlign: "center",
      paddingY: 6,
      [theme.breakpoints.down("md")]: {
        height: "100%",
        paddingY: 6,
      },
    },
    textContainer: {
      marginTop: 8,
      display: "flex",
      width: "100%",
      [theme.breakpoints.down("md")]: {
        width: "90%",
        marginTop: 3,
      },
    },
    title1: {
      fontFamily: "'Bodoni Moda', serif",
      fontSize: "38px",
      color: colors.black.light,
      fontWeight: 400,
      wordSpacing: '0.15em',
      [theme.breakpoints.down("md")]: {
        fontSize: "24px",
      },
    },
    subTitle: {
      fontFamily: "'Montserrat', sans-serif",
      fontSize: "20px",
      color: colors.secondary.main,
      fontWeight: 500,
      marginTop: 10,
      [theme.breakpoints.down("md")]: {
        fontSize: "16px",
        marginTop: 3,

      },
    },
    eslogan: {
      textAlign: "left",
      color: colors.white.main,
      fontStyle: "italic",
    },
    imgCard: {
      backgroundImage: `url(${fondoPileta1})`,
      backgroundSize: "cover",
      backgroundPosition: "center",
      height: "310px",
      width: "310px",
      borderRadius: 3,
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
      textAlign: "center",
    },
    card: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-end",
      height: "100%",
    },
    textCard: {
      color: "#fff",
      fontWeight: 500,
      fontSize: "1.5rem",
      textAlign: "left",
      background: `linear-gradient(180deg, transparent, rgba(0,0,0,.54) 27.23%, rgba(0,0,0,.62))`,
      p: 2,
      borderRadius: 3,
    },
  }

  const cardData = [
    { backgroundImage: fondoPileta1, text: "Obtén descuentos adicionales en estadías de 5 noches o más." },
    { backgroundImage: comedorImg, text: "Explora nuestro exclusivo menú de sabores de la casa." },
    {
      backgroundImage: fondoTrago1,
      text: "Relájese en nuestro sereno refugio junto a la piscina y disfrute de refrescantes cócteles.",
    },
    { backgroundImage: volleyImg, text: "Disfrute de las mejores actividades al aire libre." },
  ]

  return (
    <Box sx={styles.heroContainer}>
      <Box sx={styles.textContainer}>
        <Box sx={{ flex: 1, ml: { xs: 0, md: 5 }, paddingX: { xs: 0, md: 10 } }}>
          <Typography variant="h1" sx={styles.title1}>
            LA  COMBINACIÓN  PERFECTA  ENTRE  RELAX  Y  NATURALEZA
          </Typography>
          <Box mt={5}>
            <Typography sx={styles.subTitle}>
              Disfrute de una escapada inolvidable en nuestro hotel frente al lago, donde la tranquilidad y la
              belleza natural se fusionan para crear una experiencia única.
            </Typography>
          </Box>
          <Box mt={7} data-aos={"fade-up"} data-aos-delay={100}>
            <CardImg data={cardData} />
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default observer(AboutUs)

import AcUnitIcon from "@mui/icons-material/AcUnit"
import ShowerIcon from "@mui/icons-material/Shower"
import RestaurantIcon from "@mui/icons-material/Restaurant"
import DirectionsBikeIcon from "@mui/icons-material/DirectionsBike"
import PoolIcon from "@mui/icons-material/Pool"
import SportsVolleyballIcon from "@mui/icons-material/SportsVolleyball"
import BeachAccessIcon from "@mui/icons-material/BeachAccess"
import WifiIcon from "@mui/icons-material/Wifi"

const dummyDataAmenities = [
  {
    logo: AcUnitIcon,
    description: "Aire acondicionado en habitaciones y recepción: Garantizando un clima perfecto y confortable en todo momento.",
  },
  {
    logo: ShowerIcon,
    description: "Duchas en zonas de playa y lago: Después de disfrutar del sol y las aguas, puedes refrescarte cómodamente antes de continuar con tus actividades.",
  },
  {
    logo: RestaurantIcon,
    description: "Restaurantes con terrazas: Restaurante y cafetería con hermosas vistas al aire libre frente al lago.",
  },
  {
    logo: PoolIcon,
    description: "Piscina junto al lago: Una piscina al aire libre con vista al lago para aquellos que prefieran nadar en un entorno controlado.",
  },
  {
    logo: DirectionsBikeIcon,
    description: "Caminatas por el circuito de la urbanización y podés traer tu bicicleta 🚲 (completamente seguro, contamos con guardia 24 hs.)",
  },
  {
    logo: SportsVolleyballIcon,
    description: "Actividades recreativas: Organizar actividades recreativas como pesca, excursiones en bote, campamentos o eventos culturales alrededor del lago.",
  },
  {
    logo: BeachAccessIcon,
    description: "Acceso privado al lago: Un acceso exclusivo a una playa privada para que los huéspedes puedan disfrutar del lago a su conveniencia.",
  },
  {
    logo: WifiIcon,
    description: "Wi-Fi de alta velocidad: Asegurarse de que haya una conexión a Internet de alta velocidad para aquellos huéspedes que necesiten estar conectados durante su estancia.",
  },
]

export default dummyDataAmenities
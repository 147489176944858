import React, { useEffect, useState } from "react"
import { GoogleMap, LoadScript, MarkerF, CircleF } from "@react-google-maps/api"
import { Box, Typography } from "@mui/material"
import colors from "../../assets/theme/colors"
import { useTheme } from "@mui/material/styles"
import AOS from "aos"
import "aos/dist/aos.css"

const Map = () => {
  const mapStyles = {
    height: "50vh",
    width: "100%",
  }
  const theme = useTheme()

  const defaultCenter = {
    lat: -25.3427655, lng: -57.2830313,
  }

  const mapContainerStyles = {
    borderRadius: "16px",
    overflow: "hidden",
  }

  const circleOptions = {
    strokeColor: "#0000ff",
    strokeOpacity: 0.25,
    strokeWeight: 2,
    fillColor: "#0000ff",
    fillOpacity: 0.15,
    mapPaneName: "overlayImage",
    zIndex: 1,
  }

  const handleMapClick = () => {
    window.open("https://maps.app.goo.gl/5yBSPEEnMvVr8c747", "_blank")
  }

  useEffect(() => {
    AOS.init({
      duration: 2000,
      easing: "slide",
      once: true,
    })
  }, [])

  const [isGoogleApiLoaded, setIsGoogleApiLoaded] = useState(false)

  useEffect(() => {
    let script = document.createElement('script');
    script.src = "https://maps.googleapis.com/maps/api/js?key=AIzaSyCUGWAlRSwXHliXvvky8oQUXx1dt2FeQdo";
    script.async = true;
    script.defer = true;
    script.onload = () => setIsGoogleApiLoaded(true);
    document.body.appendChild(script);

    // Función de limpieza para eliminar el script cuando el componente se desmonte
    return () => {
      document.body.removeChild(script);
    };
  }, []); // Asegúrate de que las dependencias sean correctas


  const styles = {
    heroContainer: {
      height: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      textAlign: "center",
      [theme.breakpoints.down("md")]: {
        height: "100%",
        paddingY: 3,
      },
    },
    textContainer: {
      display: "flex",
      width: "100%",
      marginBottom: 5,
      [theme.breakpoints.down("md")]: {
        width: "90%",
        marginBottom: 0,
      },
    },
    title1: {
      fontFamily: "'Bodoni Moda', serif",
      fontSize: "38px",
      color: colors.black.light,
      fontWeight: 400,
      [theme.breakpoints.down("md")]: {
        fontSize: "24px",
      },
    },
    subTitle: {
      fontFamily: "'Montserrat', sans-serif",
      fontSize: "20px",
      color: colors.secondary.main,
      fontWeight: 500,
      marginTop: 10,
      [theme.breakpoints.down("md")]: {
        fontSize: "16px",
        marginTop: 3,
      },
    },
  }

  if (!isGoogleApiLoaded) {
    return <div>Loading Google Maps API...</div>
  }

  return (
    <>
      <Box sx={styles.heroContainer}>
        <Box sx={styles.textContainer}>
          <Box sx={{ flex: 1, ml: { xs: 0, md: 5 }, paddingX: { xs: 0, md: 10 } }}>
            <Box mt={5}>
              <Typography sx={styles.subTitle}>
                Ubíquese en el corazón de la tranquilidad y la belleza natural con nuestro hotel, donde ofrecemos una
                vista panorámica del lago y la vida silvestre que lo rodea. Perfecto para aquellos que buscan una
                escapada inolvidable.
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>

      <Box p={5} data-aos={"fade"} data-aos-delay={400}>
        <Box sx={mapContainerStyles}>
          <GoogleMap
            mapContainerStyle={mapStyles}
            zoom={15}
            center={defaultCenter}
            options={{
              streetViewControl: false,
              mapTypeControl: false,
            }}
          >
            <MarkerF position={defaultCenter} onClick={handleMapClick} />
            <CircleF
              center={defaultCenter}
              radius={150}
              options={circleOptions}
              onClick={handleMapClick}
            />
          </GoogleMap>
        </Box>
      </Box>
    </>
  )
}

export default Map

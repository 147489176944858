// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore"
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyAwSSo5FoDGdsROtMeviiDVoBF-aXt5JJk",
    authDomain: "hotel-acuario.firebaseapp.com",
    projectId: "hotel-acuario",
    storageBucket: "hotel-acuario.appspot.com",
    messagingSenderId: "604944586842",
    appId: "1:604944586842:web:cc975552e34ebfbc4fe063",
    measurementId: "G-R1R3N5GCPT"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const db = getFirestore(app)



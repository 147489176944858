import React from "react"
import { Box, Card, Typography } from "@mui/material"
import Rating from "@mui/material/Rating"
import colors from "../../assets/theme/colors"
import { useTheme } from "@mui/material/styles"
import Avatar from "@mui/material/Avatar"
import AccountCircleIcon from "@mui/icons-material/AccountCircle" // Importar el ícono de cuenta

const CarouselCard = ({ data }) => {
  const theme = useTheme()

  const getInitials = (name) => {
    return name.charAt(0).toUpperCase()
  }

  const styles = {
    container: {
      width: "100%",
      height: "100%",
      margin: "0 20px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      textAlign: "left",
      padding: 8,
      [theme.breakpoints.down("md")]: {
        padding: 1,
      },
    },
    title: {
      color: colors.black.light,
      fontWeight: 500,
      fontFamily: "'Montserrat', sans-serif",
    },
    userTitle: {
      fontSize: "20px",
      [theme.breakpoints.down("md")]: {
        fontSize: "18px",
      },
    },
  }

  return (
    <Card sx={styles.container}>
      <Box display={"flex"} gap={3}>
        <Box>
          <Avatar style={{ fontSize: "24px" }}>
            {data.user ? getInitials(data.user) : <AccountCircleIcon />}
          </Avatar>
        </Box>
        <Box>
          <Typography sx={styles.userTitle}>{data.user}</Typography>
          <Rating name="read-only" size={"small"} value={data.stars} precision={0.5} readOnly />
        </Box>
      </Box>
      <Box>
        <Typography sx={styles.title}>
          {data.title}
        </Typography>
        <Typography sx={{
          color: colors.secondary.main,
          fontWeight: 400,
          fontFamily: "'Montserrat', sans-serif",
          textAlign: 'justify'
        }}>
          {data.subtitle}
        </Typography>
      </Box>
    </Card>
  )
}

export default CarouselCard

// CardImg.js
import React from "react"
import { Box, Typography } from "@mui/material"
import { useTheme } from "@mui/material/styles"

const CardImg = ({ data }) => {
  const theme = useTheme()

  const styles = {
    container: {
      display: "grid",
      gap: "10px",
      gridTemplateColumns: "repeat(auto-fill, minmax(310px, 1fr))",

    },
    imgCard: {
      backgroundSize: "cover",
      backgroundPosition: "center",
      height: "310px",
      width: "100%",
      borderRadius: 3,
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
      textAlign: "center",
      [theme.breakpoints.down("md")]: {
        height: "210px",
        mb: 3,
      },
    },
    card: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-end",
      height: "100%",
      width: "100%",
    },
    textCard: {
      fontFamily: "'Montserrat', sans-serif",
      color: "#fff",
      fontWeight: 500,
      fontSize: "1.2rem",
      textAlign: "left",
      background: `linear-gradient(180deg, transparent, rgba(0,0,0,.54) 27.23%, rgba(0,0,0,.62))`,
      p: 2,
      borderRadius: 3,
      [theme.breakpoints.down("md")]: {
        fontSize: "1rem",
        width: "100%",
      },
    },
  }

  return (
    <div style={styles.container}>
      {data.map((item, index) => (
        <Box key={index} sx={{ ...styles.imgCard, backgroundImage: `url(${item.backgroundImage})` }}>
          <Box sx={styles.card}>
            <Typography sx={styles.textCard}>
              {item.text}
            </Typography>
          </Box>
        </Box>
      ))}
    </div>
  )
}

export default CardImg

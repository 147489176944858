import React, { useEffect } from "react"
import { observer } from "mobx-react"
import { useTheme } from "@mui/material/styles"
import { Box, Typography } from "@mui/material"
import colors from "../../assets/theme/colors"
import HabitacionCard from "./habitaciones"
import dummyDataPiezas from "./dummyDataPiezas"
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined"
import pieza07 from "../../assets/images/piezas/pieza07.webp"
import AOS from "aos"
import "aos/dist/aos.css"


function Piezas() {
  const theme = useTheme()

  useEffect(() => {
    AOS.init({
      duration: 2000,
      easing: "slide",
      once: true,
    })
  }, [])

  const styles = {
    mainContainer: {
      backgroundColor: colors.backgroundsColors.secondary,
      height: "100%",
      // display: "flex",
      // justifyContent: "center",
      alignItems: "center",
      // textAlign: "center",
      paddingY: 15,
      paddingX: 10,
      [theme.breakpoints.down("md")]: {
        height: "100%",
        paddingY: 6,
        paddingX: 2,
      },
    },
    titleText: {
      fontFamily: "'Bodoni Moda', serif",
      fontSize: "34px",
      fontWeight: 600,
    },
    divider: {
      width: "100%",
      height: 1.5,
      backgroundColor: colors.grey[300],
      marginBottom: 2,
    },

    heroContainer: {
      backgroundImage: `linear-gradient( rgba(0, 0, 0, 0.6), transparent), url(${pieza07})`,
      backgroundSize: "cover",
      backgroundPosition: "center",
      height: "720px",
      [theme.breakpoints.down("md")]: {
        height: "570px",
      },
    },
    heroContainer2: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      textAlign: "center",
      height: "100%",

    },
    textContainer: {
      display: "flex",
      width: "100%",
      [theme.breakpoints.down("md")]: {
        width: "90%",
      },
    },
    title1: {
      fontFamily: "'Bodoni Moda', serif",
      fontSize: "48px",
      color: colors.background.default,
      fontWeight: 500,
      textAlign: "center",
      [theme.breakpoints.down("md")]: {
        fontSize: "34px",
        // paddingTop: 14,
      },
    },
    locationContainer: {
      marginTop: -10,
      marginRight: 10,
      display: "flex",
      flexDirection: "row-reverse",
      cursor: "pointer",
      [theme.breakpoints.down("md")]: {
        fontSize: "34px",
        marginRight: 1,
        marginTop: -7,
      },
    },
    locationText: {
      display: "flex",
      alignItems: "center",
      backgroundColor: "#1c1c1c",
      opacity: "0.65",
      color: "#fff",
      padding: 0.6,
      borderRadius: 2,
      justifyContent: "center",
      [theme.breakpoints.down("md")]: {
        fontSize: "12px",
        marginRight: 1,
      },
    },

  }


  return (
    <>
      <Box sx={styles.heroContainer}>
        <Box sx={styles.heroContainer2}>
          <Box sx={styles.textContainer}>
            <Box sx={{ flex: 1, ml: { xs: 0, md: 5 }, paddingX: { xs: 0, md: 10 } }} data-aos={"fade-up"}
                 data-aos-delay={100}>
              <Typography variant="h1" sx={styles.title1}>
                HABITACIONES
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box sx={styles.locationContainer}
             onClick={() => window.open("https://maps.app.goo.gl/7LYmQ92htooWh1oh7?g_st=ic", "_blank")}>
          <Typography sx={styles.locationText} data-aos={"fade-up"} data-aos-delay={100}>
            <LocationOnOutlinedIcon sx={{ marginRight: 1 }} />
            San Bernardino, Cordillera, Paraguay
          </Typography>
        </Box>
      </Box>
      <Box sx={styles.mainContainer}>
        <Box>
          <Typography sx={styles.titleText}>Nuestras Habitaciones</Typography>
          <Box sx={styles.divider} />
          {dummyDataPiezas.map((habitacionData) => (
            <HabitacionCard key={habitacionData.id} data={habitacionData} />
          ))}
        </Box>
      </Box>
    </>
  )
}

export default observer(Piezas)


import React, { useEffect } from "react"
import { observer } from "mobx-react"
import { useTheme } from "@mui/material/styles"
import { Box, Typography } from "@mui/material"
import eventImg4 from "../../assets/images/evento4.webp"
import colors from "../../assets/theme/colors"
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined"
import Events from "../Events"
import AOS from "aos"
import "aos/dist/aos.css"


function EventosBody() {
  const theme = useTheme()

  useEffect(() => {
    AOS.init({
      duration: 2000,
      easing: "slide",
      once: true,
    })
  }, [])

  const styles = {
    heroContainer: {
      backgroundImage: `linear-gradient( rgba(0, 0, 0, 0.6), transparent), url(${eventImg4})`,
      backgroundSize: "cover",
      backgroundPosition: "center",
      height: "720px",
      [theme.breakpoints.down("md")]: {
        height: "570px",
      },
    },
    heroContainer2: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      textAlign: "center",
      height: "100%",

    },
    textContainer: {
      display: "flex",
      width: "100%",
      [theme.breakpoints.down("md")]: {
        width: "90%",
      },
    },
    title1: {
      fontFamily: "'Bodoni Moda', serif",
      fontSize: "48px",
      color: colors.background.default,
      fontWeight: 500,
      textAlign: "center",
      [theme.breakpoints.down("md")]: {
        fontSize: "34px",
        // paddingTop: 14,
      },
    },
    subTitle: {
      fontFamily: "'Montserrat', sans-serif",
      fontSize: "18px",
      color: colors.secondary.main,
      fontWeight: 500,
      textAlign: "center",
      padding: 8,
      [theme.breakpoints.down("md")]: {
        fontSize: "14px",
        padding: 4,
      },
    },
    button: {
      bgcolor: colors.white.main,
      borderRadius: 5,
      mt: 4,
      color: colors.textSideNav.focus,
      "&:hover": {
        bgcolor: "#dee2e6",
      },
    },
    button2: {
      // bgcolor: colors.white.main,
      borderColor: colors.white.main,
      borderRadius: 5,
      mt: 4,
      color: colors.white.main,
      "&:hover": {
        bgcolor: colors.white.main,
        color: colors.textSideNav.focus,

      },
    },
    eslogan: {
      textAlign: "left",
      color: colors.white.main,
      fontStyle: "italic",
      // textDecoration: "underline"
    },
    locationContainer: {
      marginTop: -10,
      marginRight: 10,
      display: "flex",
      flexDirection: "row-reverse",
      cursor: "pointer",
      [theme.breakpoints.down("md")]: {
        fontSize: "34px",
        marginRight: 1,
        marginTop: -7,
      },
    },
    locationText: {
      display: "flex",
      alignItems: "center",
      backgroundColor: "#1c1c1c",
      opacity: "0.65",
      color: "#fff",
      padding: 0.6,
      borderRadius: 2,
      justifyContent: "center",
      [theme.breakpoints.down("md")]: {
        fontSize: "12px",
        marginRight: 1,
      },
    },
    gridContainer: {
      padding: 10,
    },
    gridItem: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      textAlign: "center",
      // padding: 5,
      [theme.breakpoints.down("md")]: {
        // width: "20%", // 2 items en pantallas medianas
      },
      [theme.breakpoints.down("xs")]: {
        width: "100%", // 1 item en pantallas pequeñas
      },
    },
    icon: {
      fontSize: 40,
      // marginBottom: 10,
      marginRight: 3,
    },
    itemContainer: {
      display: "flex",
      alignItems: "flex-start",
      justifyContent: "center",
      marginBottom: 5,
    },

  }

  return (
    <>
      <Box sx={styles.heroContainer}>
        <Box sx={styles.heroContainer2}>
          <Box sx={styles.textContainer}>
            <Box sx={{ flex: 1, ml: { xs: 0, md: 5 }, paddingX: { xs: 0, md: 10 } }}>
              <Typography variant="h1" sx={styles.title1} data-aos={"fade-up"} data-aos-delay={100}>
                EVENTOS
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box sx={styles.locationContainer}
             onClick={() => window.open("https://maps.app.goo.gl/7LYmQ92htooWh1oh7?g_st=ic", "_blank")}>
          <Typography sx={styles.locationText} data-aos={"fade-up"} data-aos-delay={200}>
            <LocationOnOutlinedIcon sx={{ marginRight: 1 }} />
            San Bernardino, Cordillera, Paraguay
          </Typography>
        </Box>
      </Box>
      <Box mt={3}>
        {/*<Typography sx={styles.subTitle}>Veni a divertirte</Typography>*/}
        <Typography variant="h1" sx={{ ...styles.title1, color: colors.black.main, fontSize: 30, mt: 10 }}
                    data-aos={"fade-right"} data-aos-delay={300}>
          CELEBRACIONES INOLVIDABLES
        </Typography>
        <Typography sx={{ ...styles.subTitle, textAlign: 'justify' }} data-aos={"fade-up"} data-aos-delay={400}>Imagina tu boda o cumpleaños en un
          entorno donde la serenidad del lago
          se fusiona con la elegancia y la hospitalidad incomparable de nuestro hotel. Nuestro equipo de
          expertos en eventos está dedicado a hacer realidad tus sueños, asegurándose de que cada detalle sea
          perfecto. Ya sea que desees intercambiar votos con la majestuosidad del lago como telón de fondo o
          celebrar un cumpleaños inolvidable junto a las aguas serenas, estamos aquí para convertir cada
          ocasión en un recuerdo duradero. Desde espacios exclusivos hasta servicios personalizados, te
          invitamos a hacer de tu celebración un evento verdaderamente extraordinario en nuestro hotel junto
          al lago.
        </Typography>
      </Box>
      <Events />
    </>
  )
}

export default observer(EventosBody)

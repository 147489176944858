const dummyData = [
  {
    user: "Rafael J.",
    stars: 5,
    title: "Ubicación Perfecta",
    subtitle: "La vista espectacular, es todo un espectáculo, el descanso durante la noche totalmente placentero, las instalaciones internas y externas del hotel super lindas y bien cuidadas, el restaurante bello.",
  },
  {
    user: "Dario P.",
    stars: 4.5,
    title: "Almuerzo en el restaurante",
    subtitle: "Buscando una opción en San Bernardino un domingo llegamos hasta aquí.\n" +
      "\n" +
      "Es un hotel que ofrece una hermosa y privilegiada vista al Lago Ypakarai lo cual es \"novedoso\" del lugar.",
  },
  {
    user: "Victor G.",
    stars: 5,
    title: "Ideal para un fin de semana",
    subtitle: "La ubicación del Hotel es excelente, ya que está frente al Lago Ypacarai. La atención del personal es muy buena y el desayuno es bastante bueno. Las habitaciones tienen lo esencial.",
  },
  {
    user: "Celeste F.",
    stars: 4,
    title: "Excelente vista al lago",
    subtitle: "Si estas buscando escapar del ruido de la ciudad y sumergirte en la paz y tranquilidad de San Bernardino, el Hotel Acuario es una excelente opción con una relajante vista al lago y una arquitectura peculiar. En cuando al costo se ajusta para lo que se ofrece.",
  },
  {
    user: "Sandra B.",
    stars: 3.5,
    title: "Lindo",
    subtitle: "Me hospedé entresemana, pleno Enero, osea temporada alta!\n" +
      "La piscina esperaba un poco mas limpia, por suerte nos dieron un pase al club a unas cuantas cuadras de ahi y solucionaron en parte.",
  },
]

export default dummyData
// For Standard Room
import standar1 from "../../assets/images/piezas/estandar/standar1.webp"
import standar2 from "../../assets/images/piezas/estandar/standar2.webp"
import standar3 from "../../assets/images/piezas/estandar/standar3.webp"

// For Matrimonial Suite
import matrimonial1 from "../../assets/images/piezas/matrimonial/matrimonial1.webp"
import matrimonial2 from "../../assets/images/piezas/matrimonial/matrimonial2.webp"
import matrimonial3 from "../../assets/images/piezas/matrimonial/matrimonial3.webp"
import matrimonial4 from "../../assets/images/piezas/matrimonial/matrimonial4.webp"
import matrimonial5 from "../../assets/images/piezas/matrimonial/matrimonial5.webp"
import matrimonial6 from "../../assets/images/piezas/matrimonial/matrimonial6.webp"
import matrimonial7 from "../../assets/images/piezas/matrimonial/matrimonial7.webp"

// For Triple Room
import triple1 from "../../assets/images/piezas/triple/triple01.webp"
import triple2 from "../../assets/images/piezas/triple/triple2.webp"
import triple3 from "../../assets/images/piezas/triple/triple3.webp"
import triple4 from "../../assets/images/piezas/triple/triple4.webp"
import triple5 from "../../assets/images/piezas/triple/triple5.webp"

// For Quadruple Room
import cuadruple1 from "../../assets/images/piezas/cuadruple/cuadruple1.webp"
import cuadruple2 from "../../assets/images/piezas/cuadruple/cuadruple2.webp"
import cuadruple3 from "../../assets/images/piezas/cuadruple/cuadruple3.webp"
import cuadruple4 from "../../assets/images/piezas/cuadruple/cuadruple4.webp"


const dummyDataPiezas = [
  {
    id: 1,
    photos: [
      matrimonial1,
      matrimonial2,
      matrimonial3,
      matrimonial4,
      matrimonial5,
      matrimonial6,
      // matrimonial7,
    ],
    habitacion: `Matrimonial Suite`,
    description: "Nuestra suite más romántica, perfecta para parejas que buscan lujo y confort. Cama king-size y detalles exclusivos para una escapada inolvidable.",
    subDescription: "¡Incluye desayuno!",
  },
  {
    id: 2,
    photos: [
      standar1,
      standar3,
      standar2,
    ],
    habitacion: "Estándar",
    description: "Habitación cómoda  ideal para aquellos que buscan calidad. Cama confortable, espacio de trabajo y acceso a todas las instalaciones del hotel.",
    subDescription: "¡Incluye desayuno!",

  },
  {
    id: 3,
    photos: [
      triple1,
      triple2,
      triple3,
      triple4,
      triple5,
    ],
    habitacion: `Triple Estándar`,
    description: "Espaciosa habitación para familias o grupos pequeños. Tres camas individuales o combinación con cama doble, perfecta para compartir momentos especiales juntos.",
    subDescription: "¡Incluye desayuno!",
  },
  {
    id: 4,
    photos: [
      cuadruple1,
      cuadruple2,
      cuadruple3,
      cuadruple4,
    ],
    habitacion: "Cuádruple Estándar",
    description: "La opción ideal para grupos grandes o familias extendidas. Camas individuales y/o dobles, comodidades modernas y espacio suficiente para todos disfrutar de su estancia.",
    subDescription: "¡Incluye desayuno!",
  },
]

export default dummyDataPiezas
import PageLayout from "../../../components/PageLayout"
import { observer } from "mobx-react"
import NavBar from "../../../components/NavBar"
import Footer from "../../../components/Footer"
import AmenitiesBody from "../../../components/AmenitiesBody"
import EventosBody from "../../../components/EventsBody"


function Eventos() {
  return (
    <PageLayout background={"white"}>
      <NavBar />
      <EventosBody />
      <Footer />
    </PageLayout>
  )
}

export default observer(Eventos)

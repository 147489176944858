import React, { useEffect, useState } from "react"
import { observer } from "mobx-react"
import { useTheme } from "@mui/material/styles"
import { Box } from "@mui/material"
import colors from "../../assets/theme/colors"
import Carousel from "react-elastic-carousel"
import CarouselCard from "../CarouselCards"
import dummyData from "./dummy"

function Carousele() {
  const theme = useTheme()
  const [showArrows, setShowArrows] = useState(true)

  const styles = {
    mainContainer: {
      backgroundColor: colors.backgroundsColors.secondary,
      height: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      textAlign: "center",
      paddingY: 6,
      paddingX: 10,
      [theme.breakpoints.down("md")]: {
        height: "100%",
        paddingY: 6,
        paddingX: 1,
      },
    },
  }

  const breakPoints = [
    { width: 500, itemsToShow: 1 },
    { width: 768, itemsToShow: 2 },
    { width: 1200, itemsToShow: 3 },
    { width: 1500, itemsToShow: 4 },
  ]

  useEffect(() => {
    const handleResize = () => {
      // Adjust showArrows based on window width
      setShowArrows(window.innerWidth > 768)
    }

    // Initial setup
    handleResize()

    window.addEventListener("resize", handleResize)

    // Cleanup
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  return (
    <Box sx={styles.mainContainer}>
      <Carousel
        breakPoints={breakPoints}
        enableAutoPlay={true}
        autoPlaySpeed={5000}
        showArrows={showArrows}
        // isRTL={true}
      >
        {dummyData.map((data, index) => (
          <CarouselCard key={index} data={data} />
        ))}
      </Carousel>
    </Box>
  )
}

export default observer(Carousele)

import PageLayout from "../../../components/PageLayout"
import { observer } from "mobx-react"
import NavBar from "../../../components/NavBar"
import Footer from "../../../components/Footer"
import AmenitiesBody from "../../../components/AmenitiesBody"


function Amenities() {
  return (
    <PageLayout background={"white"}>
      <NavBar />
      <AmenitiesBody />
      <Footer />
    </PageLayout>
  )
}

export default observer(Amenities)

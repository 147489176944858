import React, { useEffect, useState } from "react"
import { Box, Button, Typography } from "@mui/material"
import { observer } from "mobx-react"
import Carousel from "react-elastic-carousel"
import Paper from "@mui/material/Paper"
import AcUnitIcon from "@mui/icons-material/AcUnit"
import ShowerIcon from "@mui/icons-material/Shower"
import RestaurantIcon from "@mui/icons-material/Restaurant"
import DryCleaningIcon from "@mui/icons-material/DryCleaning"
import PoolIcon from "@mui/icons-material/Pool"
import SailingIcon from "@mui/icons-material/Sailing"
import SportsVolleyballIcon from "@mui/icons-material/SportsVolleyball"
import BeachAccessIcon from "@mui/icons-material/BeachAccess"
import WifiIcon from "@mui/icons-material/Wifi"
import CircularProgress from "@mui/material/CircularProgress"
import colors from "../../assets/theme/colors"
import { getDoc, doc } from "firebase/firestore"
import { db } from "../../firebase-config"
import { useTheme } from "@mui/material/styles"


function HabitacionCard({ data }) {
  const theme = useTheme()
  const { photos, habitacion, description, amenities, subDescription } = data
  const [showArrows, setShowArrows] = useState(true)
  const [prices, setPrices] = useState({})
  const [loading, setLoading] = useState(true)


  const styles = {
    divider: {
      width: "100%",
      height: 1.5,
      backgroundColor: colors.grey[300],
      marginY: 2,
      // marginBottom: 3
    },
    container: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      paddingX: 4,
      paddingY: 5,
      gap: 5,
      [theme.breakpoints.down("md")]: {
        flexDirection: "column",
      },
    },
    promotionTag: {
      position: 'absolute',
      top: -10,
      left: -20,
      transform: 'rotate(-45deg)',
      background: 'red',
      color: 'white',
      padding: '0 15px',
      fontSize: '16px',
      fontWeight: 'bold',
      [theme.breakpoints.down("md")]: {
        top:0,
        fontSize: '12px',
      },
    },
  }

  useEffect(() => {
    const getPrices = async () => {
      const postDocumentRef1 = doc(db, "prices", "Matrimonial Suite")
      const postDocumentRef2 = doc(db, "prices", "Estándar")
      const postDocumentRef3 = doc(db, "prices", "Triple Estándar")
      const postDocumentRef4 = doc(db, "prices", "Cuádruple Estándar")

      const docSnapshot1 = await getDoc(postDocumentRef1)
      const docSnapshot2 = await getDoc(postDocumentRef2)
      const docSnapshot3 = await getDoc(postDocumentRef3)
      const docSnapshot4 = await getDoc(postDocumentRef4)


      setPrices({
        "Matrimonial Suite": docSnapshot1.data(),
        Estándar: docSnapshot2.data(),
        "Triple Estándar": docSnapshot3.data(),
        "Cuádruple Estándar": docSnapshot4.data(),
      })

      setLoading(false)
    }

    getPrices()
  }, [])


  const breakPoints = [
    { width: 500, itemsToShow: 1 },
    { width: 768, itemsToShow: 2 },
    { width: 1200, itemsToShow: 3 },
    { width: 1500, itemsToShow: 4 },
  ]

  useEffect(() => {
    const handleResize = () => {
      // Adjust showArrows based on window width
      setShowArrows(window.innerWidth > 768)
    }

    // Initial setup
    handleResize()

    window.addEventListener("resize", handleResize)

    // Cleanup
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])


  const handleReservaClick = () => {
    const message = `Hola, me gustaría reservar ${habitacion} por Gs. ${prices[habitacion]?.["1"]}`
    const encodedMessage = encodeURIComponent(message)
    const whatsappLink = `https://wa.me/+595983587892?text=${encodedMessage}`
    window.open(whatsappLink, "_blank")
  }

  return (
    <Paper elevation={0} width={"400px"}>
      <Box
        sx={styles.container}
      >
        <Box
          sx={{
            width: { xs: "320px", md: "410px", lg: "410px" },
          }}>
          <Carousel
            breakPoints={breakPoints}
            showArrows={showArrows}
            autoPlaySpeed={5000}
            enableSwipe={true}
            enableMouseSwipe={true}
          >
            {photos.map((photo, index) => (
              <Box
                key={index}
                component={"img"}
                src={photo}
                width={450}
                height={280}
              />
            ))}
          </Carousel>
        </Box>
        <Box style={{ flex: 1 }}>
          <Typography
            sx={{ fontFamily: "'Bodoni Moda', serif" }}
            fontWeight={"bold"}
            fontSize={"30px"}
          >
            {habitacion}
          </Typography>
          <Typography sx={{ fontFamily: "'Montserrat', sans-serif", textAlign: 'justify' }}>
            {description}
          </Typography>
          <Typography mt={2} color={"#43B41D"} fontWeight={"bold"}>{subDescription}</Typography>
          <Typography sx={{ fontFamily: "'Montserrat', sans-serif" }}>
            {amenities}
          </Typography>
          <Box sx={styles.divider} />
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <AcUnitIcon />
            <WifiIcon />
            <ShowerIcon />
            <RestaurantIcon />
            <DryCleaningIcon />
            <PoolIcon />
            <SportsVolleyballIcon />
            <SailingIcon />
            <BeachAccessIcon />
          </Box>
        </Box>
        <Box position="relative">
          <Box sx={styles.promotionTag}>
            Promoción
          </Box>
          <Typography
            mb={3}
            textAlign={"center"}
            fontSize={24}
            sx={{ fontFamily: "'Bodoni Moda', serif" }}
          >
            Desde
          </Typography>
          {loading? (
            <CircularProgress />
          ) : (
            <>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: 2,
                }}
              >
                <Typography
                  mb={3}
                  textAlign={"center"}
                  fontSize={30}
                >{`Gs. ${prices[habitacion]?.["1"]}`}</Typography>
                <Button variant={"contained"} onClick={handleReservaClick}>Reservar</Button>
              </Box>
            </>
          )}
        </Box>
      </Box>
    </Paper>
  )
}

export default observer(HabitacionCard)


import React, { useEffect } from "react"
import { observer } from "mobx-react"
import { useTheme } from "@mui/material/styles"
import { Box, Typography } from "@mui/material"
import colors from "../../assets/theme/colors"
import historyImg from "../../assets/images/history.webp"
import AOS from "aos"
import "aos/dist/aos.css"

function History() {
  const theme = useTheme()

  useEffect(() => {
    AOS.init({
      duration: 2000,
      easing: "slide",
      once: true,
    })
  }, [])

  const styles = {
    mainContainer: {
      backgroundColor: colors.backgroundsColors.primary,
      height: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      textAlign: "center",
      paddingY: 6,
      paddingX: 10,
      [theme.breakpoints.down("md")]: {
        height: "100%",
        paddingY: 6,
        paddingX: 1,
      },
    },
    textContainer: {
      display: "flex",
      width: "100%",
      [theme.breakpoints.down("md")]: {
        width: "90%",
        flexDirection: "column",
      },
    },
    title1: {
      fontFamily: "'Bodoni Moda', serif",
      fontSize: "44px",
      color: colors.black.light,
      fontWeight: 500,
      textAlign: "left",
      [theme.breakpoints.down("md")]: {
        fontSize: "24px",
        // paddingTop: 8,
      },
    },
    subTitle: {
      fontFamily: "'Montserrat', sans-serif",
      fontSize: "15px",
      color: colors.secondary.main,
      fontWeight: 500,
      textAlign: "left",
      marginBottom: 1,
      [theme.breakpoints.down("md")]: {
        fontSize: "16px",
        paddingTop: 3,
      },
    },
    eslogan: {
      textAlign: "left",
      color: colors.white.main,
      fontStyle: "italic",
    },
    textCard: {
      color: "#fff",
      fontWeight: 500,
      fontSize: "1.5rem",
      textAlign: "left",
      background: `linear-gradient(180deg, transparent, rgba(0,0,0,.54) 27.23%, rgba(0,0,0,.62))`,
      p: 2,
      borderRadius: 3,
    },
  }


  return (
    <Box sx={styles.mainContainer}>
      <Box sx={styles.textContainer}>
        <Box
          component="img"
          src={historyImg}
          alt={"Hotel acuario"}
          width={{ xs: "100%", md: "300px" }}
          flex={1}
          borderRadius={5}
          boxShadow={`-1px 1px 12px -3px rgba(0, 0, 0, 0.40)`}
          data-aos={"fade-right"} data-aos-delay={300}
        />
        <Box
          sx={{ flex: 1, ml: { xs: 0, md: 5 }, paddingX: { xs: 0, md: 10 } }}
          flex={1}
          data-aos={"fade-up"} data-aos-delay={400}
        >
          <Typography sx={{ ...styles.subTitle, fontWeight: "bold", color: "#9d8c58", marginBottom: 1 }}>
            Sobre nosotros
          </Typography>
          <Typography variant="h1" sx={styles.title1}>
            Hotel Acuario San Bernardino: Desde 1977 viviendo historias contigo
          </Typography>
          <Typography sx={{ ...styles.subTitle, marginTop: 2, textAlign: 'justify' }}>
            Hotel Acuario es mucho más que un lugar para descansar. Desde nuestros inicios, nos hemos dedicado a crear
            experiencias únicas para nuestros huéspedes, combinando la belleza natural de nuestro entorno con la calidez
            y el confort de nuestras instalaciones. Con una ubicación estratégica en San Bernardino, ofrecemos un
            refugio de serenidad para aquellos que buscan escapar de la rutina.
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}

export default observer(History)

import React, { useEffect } from "react"
import { observer } from "mobx-react"
import { useTheme } from "@mui/material/styles"
import { Box, Typography } from "@mui/material"
import colors from "../../assets/theme/colors"
import eventImg8 from "../../assets/images/evento09 .webp"
import eventImg9 from "../../assets/images/evento9.webp"
import AOS from "aos"
import "aos/dist/aos.css"

function Events() {
  const theme = useTheme()

  useEffect(() => {
    AOS.init({
      duration: 2000,
      easing: "slide",
      once: true,
    })
  }, [])

  const styles = {
    mainContainer: {
      backgroundColor: colors.backgroundsColors.secondary,
      height: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      textAlign: "center",
      paddingY: 6,
      paddingX: 10,
      [theme.breakpoints.down("md")]: {
        height: "100%",
        paddingY: 6,
        paddingX: 1,
      },
    },
    textContainer: {
      display: "flex",
      width: "100%",
      [theme.breakpoints.down("md")]: {
        width: "90%",
        flexDirection: "column",
      },
    },
    title1: {
      fontFamily: "'Bodoni Moda', serif",
      fontSize: "44px",
      color: colors.black.light,
      fontWeight: 500,
      textAlign: "left",
      [theme.breakpoints.down("md")]: {
        fontSize: "24px",
        // paddingTop: 8,
      },
    },
    subTitle: {
      fontFamily: "'Montserrat', sans-serif",
      fontSize: "15px",
      color: colors.secondary.main,
      fontWeight: 500,
      textAlign: "left",
      [theme.breakpoints.down("md")]: {
        fontSize: "16px",
        paddingTop: 3,
      },
    },
    eslogan: {
      textAlign: "left",
      color: colors.white.main,
      fontStyle: "italic",
    },
    textCard: {
      color: "#fff",
      fontWeight: 500,
      fontSize: "1.5rem",
      textAlign: "left",
      background: `linear-gradient(180deg, transparent, rgba(0,0,0,.54) 27.23%, rgba(0,0,0,.62))`,
      p: 2,
      borderRadius: 3,
    },
    dualImg: {
      position: "relative",
      width: "50%",
      display: "flex",
      justifyContent: "space-between",
      // zIndex: "-99"
      [theme.breakpoints.down("md")]: {
        width: "100%",
        mt: 8,
      },
    },
    img1: {
      width: "49%",
      objectFit: "cover",
      position: "relative",
      top: "15%",
      height: "350px",
      boxShadow: `-1px 1px 12px -3px rgba(0, 0, 0, 0.40)`,
      [theme.breakpoints.down("md")]: {
        height: "250px",
        // top: "-1%",
      },
    },

    img2: {
      position: "absolute",
      top: "-3%",
      right: 0,
      width: "49%",
      height: "350px",
      boxShadow: `-1px 1px 12px -3px rgba(0, 0, 0, 0.40)`, // Adjusted boxShadow
      [theme.breakpoints.down("md")]: {
        height: "250px",
        top: "-13%",

      },
    },

  }


  return (
    <Box sx={styles.mainContainer}>
      <Box sx={styles.textContainer}>

        <Box
          sx={{ flex: 1, ml: { xs: 0, md: 5 }, paddingX: { xs: 0, md: 10 } }}
          flex={1}
          data-aos={"fade-right"} data-aos-delay={300}
        >
          <Typography sx={{ ...styles.subTitle, fontWeight: 700, color: colors.gold.main, marginBottom: 1 }}>
            Eventos
          </Typography>
          <Typography variant="h1" sx={styles.title1}>
            Un hotel que se acomoda a tus eventos
          </Typography>
          <Typography sx={{ ...styles.subTitle, marginTop: 2, textAlign: 'justify' }}>
            Descubre el encanto de nuestro hotel frente al lago, el lugar perfecto para tus eventos más memorables.
            Ofrecemos un espacio único que combina la belleza natural del lago con el lujo y la comodidad de un hotel de
            primera categoría. Nuestro personal experto está listo para ayudarte a planificar y organizar cualquier tipo
            de evento, desde reuniones de negocios hasta celebraciones familiares, asegurando que cada detalle esté
            cuidadosamente atendido. Con capacidades para albergar desde pequeños grupos hasta grandes asambleas,
            nuestro hotel ofrece una variedad de salas de eventos equipadas con tecnología de vanguardia y diseño
            elegante. Aprovecha la ubicación privilegiada frente al lago para crear un ambiente naturalmente hermoso que
            añade un toque mágico a cualquier celebración. Contáctanos hoy para descubrir cómo podemos hacer que tu
            próximo evento sea el más exitoso.
          </Typography>
        </Box>
        <Box sx={styles.dualImg}>
          <Box
            component="img"
            src={eventImg9}
            alt={"Hotel acuario"}
            // width={300}
            // flex={1}
            borderRadius={5}
            sx={styles.img1}
            data-aos={"fade-up"} data-aos-delay={400}
          />
          <Box
            component="img"
            src={eventImg8}
            alt={"Hotel acuario"}
            // width={300}
            // height={400}
            // flex={1}
            borderRadius={5}
            sx={styles.img2}
            data-aos={"fade-up"} data-aos-delay={200}
          />
        </Box>
      </Box>
    </Box>
  )
}

export default observer(Events)

import React from "react"
import {
  Box,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from "@mui/material"
import MenuIcon from "@mui/icons-material/Menu"
import CloseIcon from "@mui/icons-material/Close"
import Divider from "@mui/material/Divider"
import logo1 from "../../assets/images/logo2.webp"
import RedeemIcon from "@mui/icons-material/Redeem"
import ContactPageIcon from "@mui/icons-material/ContactPage"
import { useNavigate, useLocation } from "react-router-dom"

function Navbar() {
  const navigate = useNavigate()
  const location = useLocation()

  const [anchorNav, setAnchorNav] = React.useState(null)
  const [menuOpen, setMenuOpen] = React.useState(false)
  const openMenu = (event) => {
    setAnchorNav(event.currentTarget)
    setMenuOpen(true)
  }
  const closeMenu = () => {
    setAnchorNav(null)
    setMenuOpen(false)
  }

  const pages = [
    { name: "Inicio", icon: <RedeemIcon />, route: "/" },
    { name: "Habitaciones", icon: <RedeemIcon />, route: "/habitaciones" },
    { name: "Amenities", icon: <RedeemIcon />, route: "/amenities" },
    { name: "Eventos", icon: <ContactPageIcon />, route: "/eventos" },
  ]


  return (
    <Box
      sx={{
        // display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        width: "95%",
        height: "60px",
        borderRadius: "13px",
        position: "fixed",
        top: "20px",
        backgroundColor: "#fff",
        left: "50%",
        transform: "translate(-50%)",
        zIndex: "999",
        boxShadow: "0 5px 15px 0 rgba(0, 0, 0, .25)",

      }}
    >
      <Toolbar>
        {/*<IconButton*/}
        {/*  size="large"*/}
        {/*  edge="start"*/}
        {/*  color="inherit"*/}
        {/*  aria-label="logo"*/}
        {/*  sx={{ display: { xs: "none", md: "flex" } }}*/}
        {/*>*/}
        {/*  <BedroomParentIcon />*/}
        {/*</IconButton>*/}
        <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ width: "100%" }}>
          <Box
            component={"img"}
            src={logo1}
            sx={{
              width: "130px",
              height: "43px",
              cursor: "pointer",
            }}
            onClick={() => navigate("/")}
          />
          {/*<Typography*/}
          {/*  variant="h6"*/}
          {/*  sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}*/}
          {/*>*/}
          {/*  Hotel Acuario*/}
          {/*</Typography>*/}
          <Box sx={{ display: { xs: "none", md: "flex" } }}>
            {pages.map((page, index) => (
              <Button key={index} color="inherit" sx={{
                fontWeight: 700,
                gap: 1,

              }} onClick={() => navigate(page.route)}
              >
                {/*{page.icon}*/}
                <Typography sx={{
                  fontFamily: "'Montserrat', sans-serif",
                  borderBottom: location.pathname === page.route ? `2px solid black` : "none",
                  "&:hover": {
                    borderBottom: location.pathname === page.route ? `2px solid black` : "none",
                  },
                }}>
                  {page.name}
                </Typography>
              </Button>
            ))}
          </Box>
        </Box>

        {/* Menú responsive */}
        <Box sx={{ display: { xs: "flex", md: "none" } }}>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={openMenu}
          >
            {menuOpen ? <CloseIcon /> : <MenuIcon />}
          </IconButton>
          {/* Menú desplegable */}
          <Menu
            anchorEl={anchorNav}
            open={Boolean(anchorNav)}
            onClose={closeMenu}
            sx={{
              display: { xs: "flex", md: "none" },
              "& .MuiPaper-root": {
                width: "92vw",
                maxWidth: "100vw",
              },
            }}
          >
            {pages.map((page, index) => [
              <MenuItem key={page} onClick={() => {
                navigate(page.route)
                closeMenu()
              }}>
                <Typography sx={{ fontFamily: "'Montserrat', sans-serif" }}>
                  {/*{page.icon} */}
                  {page.name}
                </Typography>
              </MenuItem>,
              index < pages.length - 1 && <Divider key={`divider-${index}`} />,
            ])}
          </Menu>
        </Box>
        {/*<IconButton*/}
        {/*    size="large"*/}
        {/*    edge="start"*/}
        {/*    color="inherit"*/}
        {/*    aria-label="logo"*/}
        {/*    sx={{display: {xs: "flex", md: "none"}}}*/}
        {/*>*/}
        {/*    <BedroomParentIcon/>*/}
        {/*</IconButton>*/}
        {/*<Typography*/}
        {/*    variant="h6"*/}
        {/*    sx={{flexGrow: 1, display: {xs: "flex", md: "none"}}}*/}
        {/*>*/}
        {/*    Hotel Acuario*/}
        {/*</Typography>*/}
      </Toolbar>
    </Box>
  )
}

export default Navbar
